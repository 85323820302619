#playersTableDiv{
    max-height: 555px;
    overflow: auto;
}

#playersTable{
    background-color: white;
    color: black;
}

#rank{
    min-width: 60px;
    width: 60px;
}

#name{
    min-width: 100px;
}

#pos{
    min-width: 37px;
}

#draft{
    min-width: 41px;
}

#gp{
    min-width: 27px;
}

#trash{
    min-width: 22px;
    width: 22px;
}

#points, #wins, #shutouts{
    min-width: 35px;
}

#pointspergame, #projPoints{
    min-width: 42px;
}

#playersHeader{
    border-bottom: 1px solid #c6c6c6;
    position: sticky;
    top: -1px;
    background-color: white;
}

.rankInput{
    padding: 5px !important;
    font-size: 12px !important;
    height: auto !important;
}

#injury {
    display: none;
}

.injury-td {
    display: none;
}

@media (max-width: 575px) {
    #injury {
        display: table-cell;
        min-width: 100px;
    }
    
    .injury-td {
        display: table-cell;
    }
}