.draftClass{
    .btn-outline-main {
        border-color: white;
        color: white;
        border-radius: 12px;
        padding: 11px 23px;
    }
    .draftH2{
        background-color: #910000;
        padding: 5px;
    }
    #draftTableDiv{
        max-height: 663px;
        overflow: auto;
    }
    #roundsDiv{
        max-height: 760px;
        overflow: auto;
    }
    .navbar{
        display: none !important;
    }
    body{
        background: #353535 !important;
        color: white;
    }
    .jumbotron{
        background: #353535 !important;
        padding-top: 55px;
    }
    #picksTable, #rosterTable{
        color: white;
    }
    #draftPlayersTable{
        background-color: white;
        color: black;
    }
    #chatDiv{
        border: 1px solid white; 
        max-height: 175px; 
        overflow: auto;
        font-size: 14px;
    }
    .chatRow{
        border-bottom: 1px solid white;
        padding: 5px;
        display: inline-flex;
    }
    .chatUsername{
        width: 60px;
        color: #dcdc2b;
        padding-right: 5px;
        vertical-align: middle;
        margin: auto;
    }
    .chatPost{
        padding: 0px 5px; 
        word-break: break-word; 
        display: inline-block;
        width: calc(100% - 120px);
        border-left: 1px solid white; 
        border-right: 1px solid white;
        vertical-align: middle;
        margin: auto;
    }
    .chatTimestamp{
        width: 60px;
        color: #dcdc2b;
        float: right;
        padding-left: 5px;
        vertical-align: middle;
        margin: auto;
    }
    #rank{
        min-width: 57px;
        width: 57px;
    }
    .pickNumCol{
        width: 31px;
    }
    .posCol{
        width: 37px;
    }
    #injury {
        display: none;
    }
    
    .injury-td {
        display: none;
    }
}
@media(max-width: 767px){
    .draftClass{
        body{
            font-size: 14px;
        }
        .jumbotron{
            padding-top: 80px;
        }
        .draftH2{
            font-size: 26px;
        }
        .draftH4{
            font-size: 18px;
        }
        #roundsDiv{
            max-height: 340px;
            overflow: auto;
        }
        #draftTableDiv{
            max-height: 415px;
            overflow: auto;
        }
        select, option{
            font-size: 12px;
        }
    }
}

@media(max-width: 575px){
    .draftClass{
        #rank{
            min-width: 36px;
            width: 36px;
        }
        #injury {
            display: table-cell;
            min-width: 100px;
        }
        .injury-td {
            display: table-cell;
        }
    }
}