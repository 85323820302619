@import './players.scss';
@import './draft.scss';

html, body{
    height: 100%;
    min-height: 100%;
}
body{
    font-family: 'Oswald', Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 400;
    background-color: #6f91a0 !important
}
.inline-block{
    display: inline-block;
}
.disabled{
    pointer-events: none !important;
}
.hidden{
    display: none !important;
}
.jumbotron{
    padding: 110px 15px 40px 15px;
    background: rgba(255, 255, 255, 0.85);
}
.navbar{
    background-color: #95c6d8 !important;
    color: white !important;
}
.form-control{
    // font-size: 14px;
    // padding: 6px 12px;
    // height: 34px;
    border-radius: 12px; 
}
.myPicks{
    background-color: #95c6d8;
    color: black;
}
.playerRow:hover, .playerRow.active{
    background-color: #0f2e3a !important;
    color: white;
}
.modal{
    color: black !important;
}
.mb5{
    margin-bottom: 5px !important;
}
.ml3{
    margin-left: 3px !important;
}
.mr5{
    margin-right: 5px !important;
}
.ml5{
    margin-left: 5px !important;
}
.mb10{
    margin-bottom: 10px !important;
}
.mb20{
    margin-bottom: 20px !important;
}
.table thead th{
	border-bottom: none !important;
}
.table{
    font-size: 14px;
    margin-bottom: 0;
    display: table;
}
.table td, .table th{
    padding: 10px 5px;
    vertical-align: middle;
}
.tac{
	text-align: center;
}
.selected{
	background-color: #2cd452;
}
.btn-main:disabled:hover{
	cursor: auto;
}
.table > tbody > tr > td, .table th{
    word-break: break-word;
}
.handCursor, .btn-main:hover, .btn-danger:hover{
    cursor: pointer;
}
.Toastify__toast-body{
    font-size: 14px;
}

/** NEW STYLES **/
label {
    font-weight: 600;
}
.alert-danger {
    background-color: #910000;
    color: white;
    border-radius: 12px;
}
.alert-main-light {
    background-color: #95c6d8;
    color: black;
    border-radius: 12px;
}
.alert-main {
    background-color: #0f2e3a;
    color: white;
    border-radius: 12px;
}
.sweet-alert .btn-success {
    background-color: #0f2e3a !important;
    border-color:  #0f2e3a !important;
    box-shadow: none !important;
    color: white;
    border-radius: 12px;
    padding: 11px 23px;
    margin-left: 10px;
}
.sweet-alert .btn-success:hover {
    opacity: 0.85;
}
.sweet-alert .btn-light {
    background-color: #d7d8d9 !important;
    border-color:  #d7d8d9 !important;
    color: black;
    border-radius: 12px;
    padding: 11px 23px;
}
.sweet-alert .btn-light:hover {
    opacity: 0.85;
}
.btn-main {
    background-color: #0f2e3a;
    border-color:  #0f2e3a;
    color: white;
    border-radius: 12px;
    padding: 11px 23px;
}
.btn-main:hover {
    background-color: #0f2e3a;
    color: white;
    opacity: 0.85;
}
.btn-outline-main {
    border-color: #0f2e3a;
    color: black;
    border-radius: 12px;
    padding: 11px 23px;
}
.btn-outline-main:hover {
    background-color: #0f2e3a;
    color: white;
    opacity: 0.85;
}
.navbar-dark .navbar-nav .nav-link {
    color: white;
}

#search {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    padding: 11px 6px;
    height: 44px;
}

#eraserDiv {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
}

div[class^=" css"][class$="-control"] {
    border-radius: 12px !important;
    padding: 3px 0px !important;
}

@media (max-width: 575px) {
    .table{
        font-size: 12px;
    }
}